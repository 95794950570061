<template>

      <div>

        <div class="item__row item__ac">

            <h2>Новости</h2>

            <v-btn
                small
                class="mx-2"
                fab
                dark
                color="#1f2b45"
                @click="chooseTypeFunction(1)"
                >
                <v-icon dark>
                    mdi-plus
                </v-icon>
            </v-btn>

        </div>

        <div class="item__column  pa-4 mb-2 news__list" v-for="item in items" :key="item.id">

            <div  class="item__row item__ac">

                <div v-for="image in item.images"  :key="image.id" >
                
                    <img  :src="'https://api.kazaerospace.kz/'+image.img_path" />
                </div>


            </div>
            <p class="mb-2">{{ item.title }}</p>
            <p class="mb-2" v-html="item.description "></p>
            <p class="mb-2">{{ item.title_kaz }}</p>
            <p class="mb-2" v-html="item.description_kaz"></p>
            <p class="mb-2">{{ item.title_eng }}</p>
            <p class="mb-2" v-html="item.description_eng"></p>
            <div class="item__row item__ac">
                <v-btn
                    small
                    class="mx-2 mr-2"
                    fab
                    dark
                    color="#1f2b45"
                    @click="show(item)"
                    >
                    <v-icon dark>
                        mdi-pencil
                    </v-icon>
                </v-btn>

                <v-btn
                    v-if="me && me.role.role == 'admin'"
                    small
                    class="mx-2 mr-2"
                    fab
                    dark
                    @click="openDeleteModal(item.id)"
                    color="#1f2b45"
                    >
                    <v-icon dark>
                        mdi-trash-can-outline
                    </v-icon>
                </v-btn>
            </div>

            <v-divider></v-divider>

        </div>
        <v-dialog v-model="destroyModal" width="750">
          <v-card class="pa-6">
            <h3 class="mb-4">Удалить запись</h3>
            <v-btn
                type="submit"
                depressed
                color="primary"
                @click="deleteItem()"
            >
              Да
            </v-btn>

            <v-btn
                depressed
                color="default"
                @click="destroyModal=false"
            >
              Отмена
            </v-btn>
          </v-card>
        </v-dialog>

        <v-dialog v-model="newsModal" width="750">
            <v-card class="pa-6">
                <v-form
                    @submit.prevent="callFunction()"
                    ref="form"
                    class="sign__page__block"
                >

                <h3 class="mb-4" v-if="type==1">Создать новость</h3>
                <h3 class="mb-4" v-else>Редактировать новость</h3>
                <div class="item__column">
                    <v-text-field
                        v-model="title"
                        label="Название"
                        required
                        outlined
                        class="input"
                        :rules="nameRules"
                    ></v-text-field>
                </div>
                <div class="item__column">
                    <p class="mb-2">Описание на русском</p>
                     <vue-editor
                        class="mb-4"
                        id="editor"
                        v-model="description"
                    >
                    </vue-editor>
                     <!-- <v-textarea
                         v-model="description"
                        filled
                        name="input-7-4"
                        label="Описание"

                        :rules="descriptionRules"

                    ></v-textarea> -->
                </div>

                <div class="item__column">
                    <v-text-field
                        v-model="title_eng"
                        label="Название английском"
                        required
                        outlined
                        class="input"
                        :rules="nameRules"
                    ></v-text-field>
                </div>
                <div class="item__column">
                    <p class="mb-2">Описание английском</p>
                    <vue-editor
                        class="mb-4"
                        id="editor"
                        v-model="description_eng"
                    >
                    </vue-editor>
            
                </div>

                <div class="item__column">
                    <v-text-field
                        v-model="title_kaz"
                        label="Название казахском"
                        required
                        outlined
                        class="input"
                        :rules="nameRules"
                    ></v-text-field>
                </div>
                <div class="item__column">
                    <p class="mb-2">Описание на казахском</p>
                    <vue-editor
                        class="mb-4"
                        id="editor"
                        v-model="description_kaz"
                    >
                    </vue-editor>
            
                </div>



                <div>
                     <v-file-input
                        chips
                        counter
                        multiple
                        show-size
                        small-chips
                        truncate-length="15"
                        v-model="files"
                    ></v-file-input>
                </div>


                <div class="item__column">
                    <div v-for="file in uploadedFiles" :key="file.id" class="item__row item__ac pointer mb-3">
                        <p class="mr-2 mb-0">{{file.img_path.split('/')[file.img_path.split('/').length-1]}}</p>
                        <i class="mdi mdi-trash-can-outline" @click="removeFile(file.id)"></i>
                    </div>
                </div>


                 <v-btn
                    type="submit"
                    depressed
                    color="primary"
                    class="mr-2"
                    >
                   Сохранить изменения
                </v-btn>

                <v-btn
                    depressed
                    color="default"
                    @click="newsModal=false"
                >
                  Отмена
                </v-btn>
                </v-form>



            </v-card>
        </v-dialog>

      </div>

</template>

<script>

export default {
  name: "News",
  data() {
    return {
         items: [],
         newsModal: false,
         destroyModal: false,
         title: '',
         description: '<div></div>',
            nameRules: [
                v => !!v || 'Заполните поле'
            ],
            descriptionRules: [
                v => !!v || 'Заполните поле'
            ],
        files: [],
        type: 0,
        newsId:'',
        me: null,
        selectedUser: null,
        uploadedFiles: [],
        title_eng:'',
        title_kaz: '',
        description_eng:  '<span></span>',
        description_kaz:  '<span></span>',
    };
  },
  methods: {
        removeFile(fileId) {
            this.$axios({
                method: "delete",
                url:
                this.$API_URL +
                this.$API_VERSION +
                "news/file/"+fileId,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
            .then((response) => {
                this.$toast.open({
                    message: response.data.message,
                    type: "success",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                });
                this.fetch();
                this.newsModal = false;
            })
            .catch((error) => {
                console.warn(error);
            });
        },
        getUser() {
            this.$axios({
                method: "get",
                url:
                this.$API_URL +
                this.$API_VERSION +
                "me",
                headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
            .then((response) => {
                this.me = response.data;
            })
            .catch((error) => {
                console.warn(error);
            });
        },
      chooseTypeFunction(type) {
          this.type = type;
          this.newsModal = true;
      },
      callFunction() {
          this.type==1?this.create():this.update();
      },
      create() {
            let contractForm = new FormData();
            for (var i = 0; i < this.files.length; i++) {
                contractForm.append("images[]", this.files[i]);
            }

            contractForm.append("title", this.title);
            contractForm.append("description", this.description);
            contractForm.append("title_eng", this.title_eng);
            contractForm.append("description_eng", this.description_eng);
            contractForm.append("title_kaz", this.title_kaz);
            contractForm.append("description_kaz", this.description_kaz);
            this.$axios
                .post(this.$API_URL + this.$API_VERSION + "news", contractForm, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                this.title =null;
                this.description = null;
                console.log(response);
                this.$toast.open({
                    message: "Успешно создано",
                    type: "success",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                });

                this.newsModal = false;
                this.type = 0;
                this.$refs.form.reset();
                this.fetch();

            })
            .catch((error) => {
                if (error.response && error.response.status == 422) {
                    this.$toast.open({
                    message: "Заполните все поля",
                    type: "error",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                    });

                }
            });
        },
        uploadFiles() {
            let contractForm = new FormData();
            for (var i = 0; i < this.files.length; i++) {
                contractForm.append("images[]", this.files[i]);
            }
            this.$axios
                .post(this.$API_URL + this.$API_VERSION + "news/files/"+this.newsId, contractForm, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                this.title =null;
                this.description = null;
                console.log(response);
                this.$toast.open({
                    message: "Успешно создано",
                    type: "success",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                });

                this.newsModal = false;
                this.type = 0;
                this.$refs.form.reset();
                this.fetch();

            })
            .catch((error) => {
                if (error.response && error.response.status == 422) {
                    this.$toast.open({
                    message: "Заполните все поля",
                    type: "error",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                    });

                }
            });
        },
        show(item) {
            this.newsId = item.id;
            this.newsModal = true;
            this.title = item.title;
            this.description =  item.description;

            this.title_eng = item.title_eng;
            this.title_kaz = item.title_kaz;

            this.description_eng = item.description_eng;
            this.description_kaz = item.description_kaz;

            this.uploadedFiles = item.images;
        },
        openDeleteModal(item) {
          this.destroyModal = true;
          this.selectedUser = item;
        },
        deleteItem() {
            this.$axios({
            method: "delete",
            url:
                this.$API_URL +
                this.$API_VERSION +
                "news/"+this.selectedUser,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
            .then((response) => {
                this.title = response.data.title;
                this.description = response.data.description;
                this.fetch();
                this.destroyModal = false
            })
            .catch((error) => {
            console.log(error);
            });
        },
        update() {
            this.$axios
                .put(this.$API_URL + this.$API_VERSION + "news/"+this.newsId,
                {
                    title: this.title,
                    description: this.description,
                    title_kaz: this.title_kaz,
                    description_kaz: this.description_kaz,
                    title_eng: this.title_eng,
                    description_eng: this.description_eng,
                }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
            .then((response) => {

                this.uploadFiles();
                this.title =null;
                this.description = null;
                console.log(response);
                this.$toast.open({
                    message: "Успешно создано",
                    type: "success",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                });
                this.newsModal = false;
                this.fetch();
            })
            .catch((error) => {
                if (error.response && error.response.status == 422) {
                    this.$toast.open({
                    message: "Заполните все поля",
                    type: "error",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                    });

                }
            });
      },
      fetch() {
        this.$axios({
          method: "get",
          url:
            this.$API_URL +
            this.$API_VERSION +
            "news",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
        .then((response) => {
            this.items = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
      }
  },
  mounted() {
      this.fetch();
      this.getUser();
  },
  beforeMount() {

  },
  watch: {
    newsModal(val) {
      if(val==false) {
          this.title_kaz = '';
          this.title_eng = '';
          this.title = '';
          this.description = '';
          this.description_eng = '';
          this.description_kaz = ''; 
          this.uploadedFiles = [];
          this.newsId = '';
          this.$refs.form.reset();
      }
    }
  },
};
</script>


<style lang="scss">
.news__list {
    img {
        width: 100px;
        height: 100px;
        object-fit: cover;
    }
}
</style>
